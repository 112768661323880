import React from "react";
import "./pagination.css";

function PhonePagination({ phonesPerPage, totalPhones, paginate }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPhones / phonesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination">
      <ul className="pagination ">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a onClick={() => paginate(number)} href="#!" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PhonePagination;
