import "./updatePhone.css";
import { useLocation, useNavigate } from "react-router-dom";
import { markalar, modeller, renkler, depolama } from "../addPhone/constants";
import axios from "axios";

import React, { useState } from "react";

const UpdatePhone = () => {
  const location = useLocation();
  const phone = location.state;
  const navigate = useNavigate();
  const [marka, setMarka] = useState(phone.marka);
  const [inputs, setInputs] = useState({
    marka: phone.marka,
    model: phone.model,
    baslik: phone.baslik,
    renk: phone.renk,
    depolama: phone.depolama,
    fiyat: phone.fiyat,
    aciklama: phone.aciklama,
  });
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { fiyat, aciklama } = inputs;

    if (!fiyat || !aciklama) {
      alert("Lütfen tüm alanları doldurun.");
      return;
    }

    await axios
      .post(
        `https://admin.atcphonemarkt.com/backend/api/updatePhone.php/${phone.id}`,
        inputs
      )
      .then((response) => {
        console.log(response.data);
        navigate("/");
      });
  };

  const handleChanges = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
    console.log(inputs);
  };

  const handleChangeMarka = (newMarka) => {
    setMarka(newMarka);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-3 text-center">
        <h1>İlanı Güncelle</h1>
        <div className="mt-3 container-sm" id="update-layout-container">
          <div className="mb-3 text-start" id="telefonMarkalarıUpdate">
            <label htmlFor="telefonMarkaUpdate" className="form-label">
              <h5>Marka</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Markası"
              id="telefonMarkaUpdate"
              name="marka"
              onChange={(e) => {
                handleChangeMarka(e.target.value);
                handleChanges(e);
              }}
            >
              {markalar?.map((marka, idx) => {
                return (
                  <option value={marka} key={idx}>
                    {marka}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="text-start" id="telefonBaslik2">
            <label htmlFor="telefonBaslikArea2" className="form-label">
              <h5>Başlık</h5>
              <input
                className="form-control"
                type="text"
                id="telefonBaslikArea2"
                name="baslik"
                onChange={handleChanges}
              ></input>
            </label>
          </div>
          <div className="mb-3 text-start mx-3" id="telefonModelleriUpdate">
            <label htmlFor="telefonModelUpdate" className="form-label">
              <h5>Model</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Modeli"
              id="telefonModelUpdate"
              onChange={handleChanges}
              defaultValue={phone.model}
              name="model"
            >
              {modeller[marka]?.map((model, idx) => {
                return (
                  <option value={model} key={idx}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start" id="telefonRenkleriUpdate">
            <label htmlFor="telefonRenkUpdate" className="form-label">
              <h5>Renk</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Rengi"
              id="telefonRenkUpdate"
              name="renk"
              defaultValue={phone.renk}
              onChange={handleChanges}
            >
              {renkler?.map((renk, idx) => {
                return (
                  <option value={renk} key={idx}>
                    {renk}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start mx-3 " id="telefonDepolamaUpdate">
            <label htmlFor="telefonDepolamaInputUpdate" className="form-label">
              <h5>Depolama</h5>
            </label>
            <select
              className="form-select"
              aria-label="Depolama"
              id="telefonDepolamaInputUpdate"
              name="depolama"
              defaultValue={phone.depolama}
              onChange={handleChanges}
            >
              {depolama?.map((depolama, idx) => {
                return (
                  <option value={depolama} key={idx}>
                    {depolama}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start " id="telefonFiyatUpdate">
            <label htmlFor="telefonFiyatInputUpdate" className="form-label">
              <h5>Fiyat</h5>
            </label>
            <input
              type="number"
              className="form-control"
              id="telefonFiyatInputUpdate"
              name="fiyat"
              defaultValue={phone.fiyat}
              onChange={handleChanges}
            />
          </div>
          <div className="mb-3 text-start mx-3 " id="telefonAciklamaUpdate">
            <label htmlFor="telefonAciklamaAreaUpdate" className="form-label">
              <h5>Açıklama</h5>
              <textarea
                className="form-control"
                id="telefonAciklamaAreaUpdate"
                rows="6"
                name="aciklama"
                defaultValue={
                  phone.aciklama
                    ? phone.aciklama.replace(/<br\s*\/?>/gi, "")
                    : ""
                }
                onChange={handleChanges}
              ></textarea>
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-outline-warning mb-3"
          id="updatePhoneBtn"
        >
          İlanı Güncelle
        </button>
      </div>
    </form>
  );
};

export default UpdatePhone;
