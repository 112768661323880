import React, { useState } from "react";
import "./addPhone.css";
import { markalar, modeller, renkler, depolama } from "./constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddPhone = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    resimler: [],
    marka: "Apple",
    baslik: "",
    model: "",
    renk: "",
    depolama: "",
    fiyat: "",
    aciklama: "",
  });

  const [marka, setMarka] = useState("Apple");
  const [uploadProgress, setUploadProgress] = useState(0); // Yükleme ilerleme yüzdesi
  const [showUploadProgress, setShowUploadProgress] = useState(false); // İlerleme kısmını gösterme durumu

  const handleChangeMarka = (newMarka) => {
    setMarka(newMarka);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    for (let i = 0; i < inputs.resimler.length; i++) {
      formData.append("resimler[]", inputs.resimler[i]);
    }

    formData.append("marka", inputs.marka);
    formData.append("model", inputs.model);
    formData.append("baslik", inputs.baslik);
    formData.append("renk", inputs.renk);
    formData.append("depolama", inputs.depolama);
    formData.append("fiyat", inputs.fiyat);
    formData.append("aciklama", inputs.aciklama);

    try {
      setShowUploadProgress(true);
      const response = await axios.post(
        "https://admin.atcphonemarkt.com/backend/api/addPhone.php",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      console.log(response.data);

      if (response.data.status === 0) {
        alert(response.data.message);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Add Phone Hatası:", error);
    } finally {
      setShowUploadProgress(false);
    }
  };

  const handleChanges = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setInputs((values) => ({ ...values, resimler: files }));
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="mt-3 text-center">
        <h1>İlan Ekle</h1>
        <div className="container-sm mt-3" id="layout-container">
          <div className="mb-3 text-start" id="telefonResimleri">
            <label htmlFor="formFileMultiple" className="form-label">
              <h5>Telefonun Resimleri</h5>
            </label>
            <input
              className="form-control text-center"
              type="file"
              id="formFileMultiple"
              onChange={handleFileChange}
              name="resimler"
              multiple
            />
          </div>
          <div className="text-start mx-3 mt-2" id="telefonBaslik">
            <label htmlFor="telefonBaslikArea" className="form-label">
              <h5>Başlık</h5>
              <input
                className="form-control"
                type="text"
                id="telefonBaslikArea"
                name="baslik"
                onChange={handleChanges}
              ></input>
            </label>
          </div>
          <div className="mb-3 text-start" id="telefonMarkaları">
            <label htmlFor="telefonMarka" className="form-label">
              <h5>Marka</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Markası"
              id="telefonMarka"
              name="marka"
              value={inputs.marka}
              onChange={(e) => {
                handleChangeMarka(e.target.value);
                handleChanges(e);
              }}
            >
              {markalar?.map((marka, idx) => {
                return (
                  <option value={marka} key={idx}>
                    {marka}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start mx-3" id="telefonModelleri">
            <label htmlFor="telefonModel" className="form-label">
              <h5>Model</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Modeli"
              id="telefonModel"
              onChange={handleChanges}
              value={inputs.model}
              name="model"
            >
              <option value="">Model Seçiniz</option>
              {modeller[marka]?.map((model, idx) => {
                return (
                  <option value={model} key={idx}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start" id="telefonRenkleri">
            <label htmlFor="telefonRenk" className="form-label">
              <h5>Renk</h5>
            </label>
            <select
              className="form-select"
              aria-label="Telefon Rengi"
              id="telefonRenk"
              name="renk"
              value={inputs.renk}
              onChange={handleChanges}
            >
              <option value="">Renk Seçiniz</option>
              {renkler?.map((renk, idx) => {
                return (
                  <option value={renk} key={idx}>
                    {renk}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start mx-3 " id="telefonDepolama">
            <label htmlFor="telefonDepolamaInput" className="form-label">
              <h5>Depolama</h5>
            </label>
            <select
              className="form-select"
              aria-label="Depolama"
              id="telefonDepolamaInput"
              name="depolama"
              value={inputs.depolama}
              onChange={handleChanges}
            >
              <option value="">Depolama Seçiniz</option>
              {depolama?.map((depolama, idx) => {
                return (
                  <option value={depolama} key={idx}>
                    {depolama}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3 text-start " id="telefonFiyat">
            <label htmlFor="telefonFiyatInput" className="form-label">
              <h5>Fiyat</h5>
            </label>
            <input
              type="number"
              className="form-control"
              id="telefonFiyatInput"
              name="fiyat"
              onChange={handleChanges}
            />
          </div>
          <div className="mb-3 text-start mx-3 " id="telefonAciklama">
            <label htmlFor="telefonAciklamaArea" className="form-label">
              <h5>Açıklama</h5>
              <textarea
                className="form-control"
                id="telefonAciklamaArea"
                rows="6"
                name="aciklama"
                onChange={handleChanges}
              ></textarea>
            </label>
          </div>
        </div>
        {showUploadProgress && (
          <div className="mb-3 text-start" id="upload-progress">
            <h5>Yükleme İlerlemesi: {uploadProgress}%</h5>
            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${uploadProgress}%` }}
                aria-valuenow={uploadProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        )}
        <button
          type="submit"
          className="btn btn-outline-success mb-3"
          id="addPhoneBtn"
        >
          İlanı Ekle
        </button>
      </div>
    </form>
  );
};

export default AddPhone;
