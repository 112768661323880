import React from "react";
import Home from "./pages/home/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddPhone from "./pages/addPhone/addPhone";
import Detail from "./pages/detail/detail";
import UpdatePhone from "./pages/update/updatePhone";
import CustomerPhones from "./pages/customerPhones/customerPhones";
import LoginForm from "./pages/login/login";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginForm />}></Route>
        <Route path="/home" element={<Home />}></Route>
        <Route path="/addPhone" element={<AddPhone />}></Route>
        <Route path="/detail/:id" element={<Detail />}></Route>
        <Route path="/update" element={<UpdatePhone />}></Route>
        <Route path="/customerPhones" element={<CustomerPhones />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
