import React, { useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import axios from "axios";
import PhoneCard from "../../components/phoneCard/phoneCard";
import PhonePagination from "../../components/pagination/pagination";
import { markalar, modeller, renkler, depolama } from "../addPhone/constants";

const Home = () => {
  const [phones, setPhones] = useState([]);
  const [phonesPerPage] = useState(20);

  /* Filtreler */
  const [marka, setMarka] = useState("Apple");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selectedModeller, setSelectedModeller] = useState([]);
  const [selectedRenkler, setSelectedRenkler] = useState([]);
  const [selectedDepolama, setSelectedDepolama] = useState([]);
  const [filteredPhones, setFilteredPhones] = useState([]);
  const [currentPhones, setCurrentPhones] = useState([]);

  const handleChangeMarka = (event) => {
    setMarka(event.target.value);
  };

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  const handleModelChange = (event) => {
    const value = event.target.value;
    if (selectedModeller.includes(value)) {
      setSelectedModeller(selectedModeller.filter((model) => model !== value));
    } else {
      setSelectedModeller([...selectedModeller, value]);
    }
  };

  const handleRenkChange = (event) => {
    const value = event.target.value;
    if (selectedRenkler.includes(value)) {
      setSelectedRenkler(selectedRenkler.filter((renk) => renk !== value));
    } else {
      setSelectedRenkler([...selectedRenkler, value]);
    }
  };

  const handleDepolamaChange = (event) => {
    const value = event.target.value;
    if (selectedDepolama.includes(value)) {
      setSelectedDepolama(
        selectedDepolama.filter((depolama) => depolama !== value)
      );
    } else {
      setSelectedDepolama([...selectedDepolama, value]);
    }
  };

  useEffect(() => {
    // Telefonları getir
    const fetchData = async () => {
      const response = await axios.get(
        "https://admin.atcphonemarkt.com/backend/api/getPhones.php"
      );
      const fetchedPhones = response.data;

      // Tüm telefonları içerecek şekilde güncelle
      setPhones(fetchedPhones);
      // İlk sayfa için telefonları ayarla
      setCurrentPhones(fetchedPhones.slice(0, phonesPerPage));
      // Filtrelenmiş telefonları tüm telefonlar olarak ayarla
      setFilteredPhones(fetchedPhones);
    };

    fetchData();
  }, [phonesPerPage]);

  const fetchPhones = () => {
    axios
      .get("https://admin.atcphonemarkt.com/backend/api/getPhones.php")
      .then((response) => {
        setPhones(response.data);
      });
  };

  const applyFilters = () => {
    const filteredPhones = phones.filter((phone) => {
      // Fiyat filtresi
      const phonePrice = parseFloat(phone.fiyat);
      if (!isNaN(phonePrice)) {
        if (minPrice !== "" && phonePrice < parseFloat(minPrice)) {
          return false;
        }
        if (maxPrice !== "" && phonePrice > parseFloat(maxPrice)) {
          return false;
        }
      }

      // Marka filtresi
      if (marka !== "Tümü" && phone.marka !== marka) {
        return false;
      }

      // Diğer filtreler burada eklenir (model, renk, depolama vs.)

      if (
        selectedModeller.length > 0 &&
        !selectedModeller.includes(phone.model)
      ) {
        return false;
      }

      if (selectedRenkler.length > 0 && !selectedRenkler.includes(phone.renk)) {
        return false;
      }

      if (
        selectedDepolama.length > 0 &&
        !selectedDepolama.includes(phone.depolama)
      ) {
        return false;
      }

      return true;
    });

    // filteredPhones'i güncelleme
    setFilteredPhones(filteredPhones);

    // currentPhones'i güncelleme
    setCurrentPhones(filteredPhones.slice(0, phonesPerPage)); // Sayfa başına belirli sayıda telefon göstermek için güncellendi
  };

  const resetFilters = () => {
    setMarka("Apple");
    setMinPrice("");
    setMaxPrice("");
    setSelectedModeller([]);
    setSelectedRenkler([]);
    setSelectedDepolama([]);
    applyFilters();
    fetchPhones();
    setFilteredPhones(phones);
    setCurrentPhones(phones.slice(0, phonesPerPage));
  };

  const paginate = (pageNumber) => {
    const indexOfLastPhone = pageNumber * phonesPerPage;
    const indexOfFirstPhone = indexOfLastPhone - phonesPerPage;
    setCurrentPhones(filteredPhones.slice(indexOfFirstPhone, indexOfLastPhone));
  };

  return (
    <div className="text-center mt-3">
      <div className="container-sm" id="home-container">
        <div className="buttons-container"></div>
        <div id="filter-container">
          <Link to="/addPhone" className="link">
            <button
              type="button"
              className="btn btn-outline-success mt-2"
              id="add-phone"
            >
              İlan Yükle
            </button>
          </Link>
          <Link to="" aria-disabled className="link">
            <button
              type="button"
              className="btn btn-outline-info mt-2 mb-2 disabled"
              id="add-phone"
            >
              Müşteri Telefonları - Yakında
            </button>
          </Link>
          <div className="filter-accordion-container">
            <div className="accordion" id="accordion-parent">
              {/* Fiyat Filtresi */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Fiyat
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-bs-parent="#accordion-parent"
                >
                  <div className="accordion-body">
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="floatingMinPrice"
                        placeholder="Min. Fiyat"
                        value={minPrice}
                        onChange={handleMinPriceChange}
                      />
                      <label htmlFor="floatingMinPrice">Min. Fiyat</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="number"
                        className="form-control"
                        id="floatingMaxPrice"
                        placeholder="Max. Fiyat"
                        value={maxPrice}
                        onChange={handleMaxPriceChange}
                      />
                      <label htmlFor="floatingMaxPrice">Max. Fiyat</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Marka Filtresi */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Marka
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordion-parent"
                >
                  <div className="accordion-body">
                    {markalar.map((marka2, idx) => (
                      <div className="form-check" key={idx}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="marka"
                          value={marka2}
                          checked={marka2 === marka ? true : false}
                          id={"flexCheckDefault" + idx}
                          onChange={handleChangeMarka}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={"flexCheckDefault" + idx}
                        >
                          {marka2}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Modeller Filtresi */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                  >
                    Modeller
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordion-parent"
                >
                  <div className="accordion-body">
                    {modeller[marka]?.map((model, idx) => {
                      return (
                        <div className="form-check" key={idx}>
                          <input
                            key={idx}
                            className="form-check-input"
                            type="checkbox"
                            value={model}
                            id={"flexCheckDefault" + idx}
                            checked={selectedModeller.includes(model)}
                            onChange={handleModelChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + idx}
                          >
                            {model}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Renkler Filtresi */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Renkler
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordion-parent"
                >
                  <div className="accordion-body">
                    {renkler?.map((renk, idx) => {
                      return (
                        <div className="form-check" key={idx}>
                          <input
                            key={idx}
                            className="form-check-input"
                            type="checkbox"
                            value={renk}
                            id={"flexCheckDefault" + idx}
                            checked={selectedRenkler.includes(renk)}
                            onChange={handleRenkChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + idx}
                          >
                            {renk}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Depolama Filtresi */}
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    Depolama
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordion-parent"
                >
                  <div className="accordion-body">
                    {depolama?.map((depolama, idx) => {
                      return (
                        <div className="form-check" key={idx}>
                          <input
                            key={idx}
                            className="form-check-input"
                            type="checkbox"
                            value={depolama}
                            id={"flexCheckDefault" + idx}
                            checked={selectedDepolama.includes(depolama)}
                            onChange={handleDepolamaChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"flexCheckDefault" + idx}
                          >
                            {depolama}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Filtreleme Düğmeleri */}
          <div className="filters-btns-container">
            <button
              type="button"
              className="btn btn-outline-success mb-2"
              onClick={applyFilters}
            >
              Uygula
            </button>
            <button
              type="button"
              className="btn btn-outline-warning mb-2"
              onClick={resetFilters}
            >
              Sıfırla
            </button>
          </div>
        </div>
        <div id="phones-container">
          {phones.length === 0 ? (
            <span>Şu anda yüklü hiç ilanınız yok, lütfen ilan yükleyiniz.</span>
          ) : currentPhones.length === 0 ? (
            <span>Aradığınız kriterlere uygun ilan bulunamadı.</span>
          ) : (
            <>
              <div className="data-container">
                {currentPhones.map((phone, idx) => {
                  return <PhoneCard phone={phone} key={idx} />;
                })}
              </div>
            </>
          )}
          <div id="pagination-container" className="pagination-container">
            <PhonePagination
              phonesPerPage={phonesPerPage}
              totalPhones={filteredPhones.length}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
