import React from "react";
import "./phoneCard.css";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

const PhoneCard = ({ phone }) => {
  const firstImg = phone.resimler.split(",")[0];

  return (
    <Link to={`/detail/id=${phone.id}`} state={phone} id="link">
      <div id="phoneCard">
        <div id="image">
          <img
            src={`https://admin.atcphonemarkt.com/phone-images/${firstImg}`}
            alt="phone-img"
            id="phone-img"
          />
        </div>
        <div className="d-flex w-100 flex-column py-1">
          <div
            id="phone-baslik"
            className=" text-start mx-2 d-flex justify-content-between"
          >
            {phone.baslik ??
              "FULL AKSESUARLI 128 GB İPHONE 11 PRO MAX SPACE GRAY"}
            <i
              className="fa-solid fa-star"
              id={phone.favori === "1" ? "fa-star-favori" : "fa-star"}
            ></i>
          </div>
          <hr id="hr" />
          <div id="phone-model" className="text-start d-flex mx-2">
            {phone.model} - {phone.depolama}
            <div className="text-start ms-auto">
              <NumericFormat
                value={phone.fiyat}
                displayType="text"
                thousandSeparator="."
                decimalSeparator="false"
                suffix="₺"
                id="fiyat"
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PhoneCard;
