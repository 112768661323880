import "./detail.css";
import { useLocation, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import axios from "axios";

const Detail = () => {
  const location = useLocation();
  const phone = location.state;
  const navigate = useNavigate();

  const deletePhone = async (id) => {
    const userConfirmed = window.confirm(
      "Telefonu silmek istediğinize emin misiniz?"
    );
    if (userConfirmed) {
      await axios
        .get(
          `https://admin.atcphonemarkt.com/backend/api/deletePhone.php/${id}`
        )
        .then((response) => {
          console.log(response.data);
          navigate("/");
        });
    }
  };
  const handleFavori = async (id) => {
    if (phone.favori === "1") {
      await axios
        .post(
          `https://admin.atcphonemarkt.com/backend/api/favPhone.php/${id}/${0}`
        )
        .then((response) => {
          console.log(response.data.message);
          navigate("/");
        });
    } else if (phone.favori === "0") {
      await axios
        .post(
          `https://admin.atcphonemarkt.com/backend/api/favPhone.php/${id}/${1}`
        )
        .then((response) => {
          console.log(response.data.message);
          navigate("/");
        });
    }
  };
  return (
    <>
      <h1 className="text-center mt-3">{phone.baslik}</h1>
      <div className="container-sm " id="detail-container-sm">
        <div className="carousel-container-sm p-3">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div
              className="carousel-indicators"
              id="carouselIndicatorContainer"
            >
              {phone.resimler.split(",").map((resim, idx) => {
                return (
                  <button
                    key={idx}
                    type="button"
                    id="indicatorBtn"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide-to={idx}
                    className={idx === 0 ? "active" : ""}
                    aria-current="true"
                    aria-label={`Slide ${idx}`}
                  >
                    <div id="indicatorContainer">
                      <img
                        id="indicatorImg"
                        src={`https://admin.atcphonemarkt.com/phone-images/${resim}`}
                        alt="phone-img"
                      />
                    </div>
                  </button>
                );
              })}
            </div>
            <div className="carousel-inner">
              {phone.resimler.split(",").map((resim, idx) => {
                return (
                  <div
                    className={
                      idx === 0 ? "carousel-item active" : "carousel-item"
                    }
                    key={idx}
                  >
                    <img
                      src={`https://admin.atcphonemarkt.com/phone-images/${resim}`}
                      className="d-block w-100"
                      alt="phone-img"
                    />
                  </div>
                );
              })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>

        <div className="description-container m-3">
          <h3 className="m-1 text-start">Açıklama</h3>
          <hr />
          <div
            className="text-start mt-3"
            dangerouslySetInnerHTML={{ __html: phone.aciklama }}
          ></div>
        </div>
        <div className="phone-detail-container m-3">
          <h3 className="m-3 text-start">
            Fiyat :
            <NumericFormat
              value={phone.fiyat}
              displayType="text"
              thousandSeparator="."
              decimalSeparator="false"
              suffix="₺"
              id="fiyat"
            />{" "}
          </h3>
          <hr className="horizontal-line" />
          <h3 className="m-3 text-start">Detaylar</h3>
          <hr className="horizontal-line" />
          <h5 className="m-3">
            Marka : <span>{phone.marka}</span>
          </h5>
          <h5 className="m-3">
            Model : <span>{phone.model}</span>
          </h5>
          <h5 className="m-3">
            Renk : <span>{phone.renk}</span>
          </h5>
          <h5 className="m-3">
            Depolama : <span>{phone.depolama}</span>
          </h5>
          <h5 className="m-3">İlan Yüklenme Tarihi : {phone.tarih}</h5>
          <div className="phone-settings-container m-3">
            <button
              type="button"
              className={
                phone.favori === "1"
                  ? "btn btn-outline-danger"
                  : "btn btn-outline-info"
              }
              onClick={() => {
                handleFavori(phone.id);
              }}
            >
              {phone.favori === "1" ? "Favorilerden Çıkar" : "Favorilere Ekle"}
            </button>
            <Link to="/update" state={phone} id="updatePhoneLink">
              <button type="button" className="btn btn-outline-warning">
                Güncelle
              </button>
            </Link>
            <button
              type="button"
              onClick={() => {
                deletePhone(phone.id);
              }}
              className="btn btn-outline-danger"
            >
              Sil
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
